import { PageWrapper } from '@coverright/ui/shared';
import * as React from 'react';
import {
  convertPreferredDrugInput,
  DrugSelection,
  PreferredDrugOutput,
} from '@coverright/widgets';
import { PdpQuoteContext, ProfileContext } from '@coverright/shared/contexts';
import { getCId, getPdpQuoteId } from '@coverright/utils';
import { identifyHotjar } from '@coverright/shared/analytics';
import { Container } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

export default function Drugs() {
  const { zip, countyName } = useParams<{ zip: string; countyName: string }>();
  const profile = React.useContext(ProfileContext);
  const navigate = useNavigate();
  const {
    preferredDrugs,
    clientId,
    save,
    saveClientInfo: savePreferredDrugs,
  } = React.useContext(PdpQuoteContext);
  const [drugs, setDrugs] = React.useState<PreferredDrugOutput[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setDrugs(preferredDrugs || []);
  }, [preferredDrugs]);

  const saveDrugs = (clientId: string) => {
    return savePreferredDrugs({
      variables: {
        clientId,
        preferredDrugs: drugs.map(convertPreferredDrugInput),
      },
    })
      .then(() => {
        if (drugs.length) {
          identifyHotjar({ drugs_used: true });
        }
        navigate(`/plans/${zip}/${countyName}`);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (profile?.profileId && !profile?.pdpQuoteId && !getPdpQuoteId()) {
      save({
        variables: {
          input: {
            cId: getCId(),
            countyName,
            zip,
          },
        },
      });
    }
  }, [profile]);

  const onNextClick = React.useCallback(() => {
    setLoading(true);
    if (clientId) {
      saveDrugs(clientId);
    } else {
      save({
        variables: {
          input: {
            cId: getCId(),
            countyName,
            zip,
          },
        },
      }).then((res: any) => saveDrugs(res.data?.savePdpQuote.clientId));
    }
  }, [clientId, drugs]);

  return (
    <Container maxWidth={'md'} sx={{ mb: 30 }}>
      <PageWrapper
        title={'Enter any prescription drugs you need covered.'}
        subtitle={'We will recommend a prescription drug plan for you.'}
        maxWidth={700}
        onNextButtonClick={onNextClick}
        nextButtonDisabled={loading || !drugs.length}
        loading={loading}
        nextButtonTitle={'Continue'}
        formName={'PDP Drugs'}
      >
        <div className={'h-40'} />
        <DrugSelection value={drugs} onChange={setDrugs} />
        <div className={'h-24'} />
      </PageWrapper>
    </Container>
  );
}
