import { PageWrapper } from '@coverright/ui/shared';
import { colors } from '@coverright/ui/themes';
import { Button, Container, Typography } from '@mui/material';
import * as React from 'react';
import { PharmaciesSelection } from '@coverright/features';
import { PdpQuoteContext } from '@coverright/shared/contexts';
import { useFindPharmacyByNpi } from '@coverright/data-access/medicare';
import { ClientDrugDeliveryType } from '@coverright/data-access/types/enrollment';
import {
  MedigapDrugDeliveryType,
  PharmacyLocationDistanceOutput,
} from '@coverright/data-access/types/medicare';
import { useNavigate, useParams } from 'react-router-dom';

export default function Pharmacies() {
  const { zip, countyName, planId } = useParams<{
    zip: string;
    countyName: string;
    planId: string;
  }>();
  const navigate = useNavigate();

  const {
    preferredPharmacies,
    drugDeliveryType,
    clientId,
    saveClientInfo: savePreferredPharmacies,
  } = React.useContext(PdpQuoteContext);

  const [pharmacies, setPharmacies] = React.useState<
    PharmacyLocationDistanceOutput[]
  >([]);

  const [orderType, setOrderType] = React.useState<
    MedigapDrugDeliveryType | undefined
  >(drugDeliveryType || undefined);

  const [loading, setLoading] = React.useState(false);

  const [getPharmacy] = useFindPharmacyByNpi({
    onCompleted: (data) => {
      if (data?.findPharmacyByNpi) {
        setPharmacies([data.findPharmacyByNpi]);
      }
    },
  });

  React.useEffect(() => {
    setOrderType(drugDeliveryType || undefined);
  }, [drugDeliveryType]);

  React.useEffect(() => {
    if (preferredPharmacies?.length) {
      getPharmacy({ variables: { npi: preferredPharmacies[0] } });
    }
  }, [preferredPharmacies]);

  const onNextClick = React.useCallback(() => {
    setLoading(true);
    savePreferredPharmacies({
      variables: {
        clientId,
        preferredPharmacies:
          orderType === MedigapDrugDeliveryType.Pharmacy
            ? pharmacies?.map((p) => p.npi)
            : [],
        drugDeliveryType: orderType as unknown as ClientDrugDeliveryType,
      },
    })
      .then(() => {
        navigate(`/plans/${zip}/${countyName}`);
      })
      .finally(() => setLoading(false));
  }, [clientId, orderType, pharmacies]);

  return (
    <Container maxWidth={'md'} sx={{ mb: 30 }}>
      <Button
        startIcon={<img src={'/assets/img/arrow-left.svg'} />}
        onClick={() => navigate(`/drugs/${zip}/${countyName}`)}
      >
        Back to drugs
      </Button>

      <PageWrapper
        title={'Where do you normally fill your prescriptions?'}
        subtitle={
          'Entering this allows us to see if you’re eligible for lower cost-share.'
        }
        onNextButtonClick={onNextClick}
        nextButtonDisabled={loading}
        loading={loading}
        nextButtonTitle={'Continue'}
        nextButtonExtra={
          <Typography
            className={'pointer fs-14 underline'}
            align={'center'}
            onClick={() => {
              navigate(`/plans/${zip}/${countyName}`);
            }}
            sx={{ color: colors.custom.green.variant2 }}
          >
            Click here to skip this step.
          </Typography>
        }
        formName={'PDP Drugs'}
      >
        <div className={'h-40'} />
        <PharmaciesSelection
          zip={zip}
          value={pharmacies}
          onChange={setPharmacies}
          orderType={orderType}
          onOrderTypeChange={(d) =>
            setOrderType(d as unknown as MedigapDrugDeliveryType)
          }
        />
      </PageWrapper>
    </Container>
  );
}
