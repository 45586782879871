import { ThemeProvider } from '@mui/material';
import { AppTheme } from '@coverright/ui/themes';
import { LayoutWithNotification } from '@coverright/ui/marketplaces';
import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Route, useNavigate, Routes, Navigate
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  getAgeByBirthdate, getClientId,
  getPdpQuoteId,
  getStoredUser, resetStoredUser,
  setStoredUser
} from '@coverright/utils';
import moment from 'moment';
import { withKeycloakContext, KeycloakContext, useIsAdmin } from '@coverright/shared/keycloak';
import { client, setErrorHandler } from '@coverright/data-access/apollo-clients';
import {
  AdminContextProvider, CompareOfferContextProvider, PdpQuoteProvider,
  ProfileContext, withAppContextProvider
} from '@coverright/shared/contexts';
import { Gender } from '@coverright/data-access/types/medigap';
import AdminNotification from '../components/AdminNotification';
import Drugs from './drugs/drugs';
import Plans from './plans/plans';
import Pharmacies from './pharmacies/pharmacies';
import * as _ from 'lodash';
import { CRPreloader } from '@coverright/ui/shared';

export function App() {
  const { enqueueSnackbar } = useSnackbar();
  const {keycloak, initialized, isAdmin} = React.useContext(KeycloakContext);
  const urlParams = new URLSearchParams(window.location.search);

  React.useEffect(() => {
    if (initialized && !keycloak?.authenticated) {
      document.location.href = keycloak.createLoginUrl({redirectUri: document.location.href})
    }
  }, [initialized, keycloak?.authenticated]);

  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path, extensions }: any) => {
        switch (extensions?.status) {
          case '403':
          {
            resetStoredUser();
            document.location.reload();
          }
            break;
          default: {
            console.error(
              `Message: ${message}, Location: ${JSON.stringify(
                locations
              )}, Path: ${path}`
            );
            enqueueSnackbar('Network error, please try again later', {
              variant: 'error',
            });
          }
        }
      });
  });

  React.useEffect(() => {
    const birthDate =
      urlParams.get('birthdate') && moment(urlParams.get('birthdate')).isValid()
        ? moment(urlParams.get('birthdate')).format('YYYY-MM-DD')
        : getStoredUser()?.birthDate;
    setStoredUser({
      zip: urlParams.get('zip') || getStoredUser()?.zip,
      firstName: urlParams.get('name') || getStoredUser()?.firstName,
      lastName: urlParams.get('last_name') || getStoredUser()?.lastName,
      birthDate,
      age: birthDate
        ? getAgeByBirthdate(birthDate).toString()
        : getStoredUser()?.age,
      phoneNumber: urlParams.get('phone') || getStoredUser()?.phoneNumber,
      email: urlParams.get('email') || getStoredUser()?.email,
      pdpQuoteId:
        urlParams.get('pdpQuote') || getStoredUser()?.pdpQuoteId,
      gender: urlParams.has('gender')
        ? urlParams.get('gender') === 'female'
          ? Gender.F
          : Gender.M
        : getStoredUser()?.gender,
    });

  }, []);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={AppTheme}>
        <AdminContextProvider clientId={getClientId()}>
          <CompareOfferContextProvider adminOnly>
            <PdpQuoteProvider quoteId={urlParams.has('adminQuote') ? urlParams.get('adminQuote') as string : getPdpQuoteId()}>
              <LayoutWithNotification showNotification NotificationComponent={<AdminNotification />}>
                <Helmet>
                  <title>CoverRight</title>
                  <meta
                    name="description"
                    content="Find the best Medicare plan for you"
                  />
                </Helmet>
                <Router />
              </LayoutWithNotification>
            </PdpQuoteProvider>
          </CompareOfferContextProvider>
        </AdminContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default _.flowRight([withKeycloakContext, withAppContextProvider])(App);

const Router = () => {
  const {zip, countyName} = React.useContext(ProfileContext);
  const isAdmin = useIsAdmin();

  if (!zip && !isAdmin) {
    return <CRPreloader sx={{ height: '100vh', display: 'flex', alignItems: 'center', my: 0 }}/>
  }

  return <Routes>
    <Route
      path={'/:zip/:countyName'}
      element={<Drugs />}
    />
    <Route
      path={'/drugs/:zip/:countyName'}
      element={<Drugs />}
    />
    <Route
      path={'/pharmacies/:zip/:countyName'}
      element={<Pharmacies />}
    />
    <Route
      path={'/plans/:zip/:countyName'}
      element={<Plans />}
    />
    <Route
      path={'*'}
      element={<Navigate to={`/${zip}/${countyName}`} />}
    />
  </Routes>
}
