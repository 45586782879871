import {  useLazyQuery, LazyQueryHookOptions } from '@apollo/client';
import {gql} from "@apollo/client";
import { PharmacyLocationDistanceOutput, QueryFindPharmacyByNpiArgs } from '@coverright/data-access/types/medicare';

export function useFindPharmacyByNpi(options?: LazyQueryHookOptions<IFindPharmacyResponse, QueryFindPharmacyByNpiArgs>) {
  return useLazyQuery<IFindPharmacyResponse, QueryFindPharmacyByNpiArgs>(gql(pharmacyRequest))
}

interface IFindPharmacyResponse {
  findPharmacyByNpi?: PharmacyLocationDistanceOutput | null
}

const pharmacyRequest = `
query ($npi: String!, $lengthUnit: LengthUnit, $zipCode: String) {
  findPharmacyByNpi(npi: $npi, lengthUnit: $lengthUnit, zipCode: $zipCode) {
    address
    distance
    latitude
    longitude
    name
    npi
    zip
  }
}`;
