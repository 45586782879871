import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { MutationCreatePdpQuoteForClientArgs, PdpQuoteOutput } from '@coverright/data-access/types/medicare';

export function useCreatePdpQuoteForClient(options?: MutationHookOptions<{createPdpQuoteForClient: PdpQuoteOutput}, MutationCreatePdpQuoteForClientArgs>) {
    return useMutation<{createPdpQuoteForClient: PdpQuoteOutput}, MutationCreatePdpQuoteForClientArgs>(gql(mutation), {
        ...options
    })
}

const mutation = `
mutation ($clientId: UUID!, $userId: UUID, $input: PdpQuoteInput!) {
  createPdpQuoteForClient(input: $input, clientId: $clientId, userId: $userId) {
    cId
    clientId
    countyName
    drugDeliveryType
    id
    insulinSavings
    preferredPharmacies
    zip
  }
}
`;
