import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { AdminContext, CompareOfferContext, PdpQuoteContext } from '@coverright/shared/contexts';
import { useSnackbar } from 'notistack';
import { getAgeByBirthdate, getStoredUser, setPdpQuoteId } from '@coverright/utils';
import LoopRoundedIcon from '@mui/icons-material/LoopRounded';
import { useCreatePdpQuoteForClient } from '@coverright/data-access/medicare';
import { environment } from '../environments/environment';

const AdminNotification = () => {
  const adminContext = React.useContext(AdminContext);
  const compareOfferContext = React.useContext(CompareOfferContext);
  const fullName = `${adminContext?.clientView.firstName} ${adminContext?.clientView.lastName}`.trim();
  const [create] = useCreatePdpQuoteForClient();
  const { enqueueSnackbar } = useSnackbar();
  const quoteContext = React.useContext(PdpQuoteContext);

  const createOffer = React.useCallback(() => {
    if (adminContext) {
      create({variables: {
        clientId: adminContext.clientId,
        input: {
          zip: adminContext.clientView.zip as string || getStoredUser()?.zip as string,
          countyName: adminContext.clientView.countyName as string || getStoredUser()?.countyName as string,
          insulinSavings: false,
        }
      }}).then(res => {
        if (res.data?.createPdpQuoteForClient) {
          setPdpQuoteId(res.data.createPdpQuoteForClient.id);
        }
      })
        .then(res => {
          enqueueSnackbar('Offer created', {variant: 'success'});
        })
        .catch(() => {
          enqueueSnackbar('Error creating offer', {variant: 'error'});
        });
    }
  }, [adminContext]);

  const isOffer = React.useMemo(() => {
    return !!quoteContext.id && quoteContext.id === adminContext?.clientView?.pdpQuote?.id;
  }, [adminContext?.clientView?.pdpQuote, quoteContext?.id]);

  const switchToQuote = (quoteId: string) => {
    setPdpQuoteId(quoteId);
  }

  const QuoteSelector = React.useMemo(() => {
    return null
    if (!adminContext?.clientView?.pdpQuote) {
      return <Typography className={`fs-18 bold white underline ml-20 pointer`} onClick={createOffer} variant={'h4'}>Create offer</Typography>
    } else {
      if (quoteContext.id === adminContext?.clientView?.pdpQuote?.id) {
        return <div className={'flex flex-align-center'}>
          <LoopRoundedIcon className={`pointer ml-20`}
                           onClick={() => switchToQuote(adminContext?.clientView?.pdpQuote?.id)}/>
          <Typography className={`fs-12 white underline ml-20 pointer`} onClick={() => {
            navigator.clipboard.writeText(`${document.location.origin}?adminQuote=${adminContext?.clientView?.pdpQuote?.id}`)
            enqueueSnackbar('Copied!', {variant: "info"});
          }}>(copy link)</Typography>
        </div>
      } else {
        return <div className={'flex flex-align-center'}>
          <LoopRoundedIcon className={`pointer ml-20`}
                           onClick={() => switchToQuote(adminContext?.clientView?.pdpQuote?.id)}/>
          <Typography className={`fs-12 white underline ml-20 pointer`} onClick={() => {
            navigator.clipboard.writeText(`${document.location.origin}?quote=${adminContext?.clientView?.pdpQuote?.id}`)
            enqueueSnackbar('Copied!', {variant: "info"});
          }}>(copy link)</Typography>
        </div>
      }
    }
  }, [adminContext?.clientView?.pdpQuote, quoteContext?.id]);

  if (adminContext == null) {
    return null
  }

  return <Box sx={{
    height: '50px',
    padding: '0 18px',
    color: 'white',
    background: isOffer ? '#3f3efb' : '#FB0A0A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }}>
    <Box display={'flex'} flex={1} alignItems={'center'} justifyContent={'space-between'} mr={3}>
      <Box display={'flex'}>
        <Typography className={'fs-18 bold white'} variant={'h4'}>{`${fullName}'s ${isOffer ? 'offer' : 'quote'}`}</Typography>
        {QuoteSelector}
      </Box>
      {!!compareOfferContext.plans.length && <Typography className={`fs-14 white underline ml-20 pointer`} onClick={() => {
        document.location.href = environment.dashboardUrl + 'pdp-compare-offer?clientId=' + adminContext.clientId;
      }}>Preview compare offer</Typography>}
    </Box>
    <IconButton onClick={adminContext.clear}><img src={'/assets/img/white-close.svg'} /></IconButton>
  </Box>
};

export default AdminNotification;
