import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { CRPreloader } from '@coverright/ui/shared';
import { PdpPlanWithTiers, usePDPPlans } from '@coverright/data-access/medicare';
import { MedigapPreferredDrugOutput, PdpField, PdpSort, PlanYear } from '@coverright/data-access/types/medicare';
import { PdpPlanCard } from './PdpPlanCard';
import { GetNextPlansYear } from '@coverright/utils';

type PdpPlanListProps = {
  zip: string,
  countyName: string,
  clientId?: string,
  insulinSavings?: boolean,
  planYear?: PlanYear,
  sort?: PdpField,
  onSelectClick?: (plan: PdpPlanWithTiers) => void,
  onPharmacyClick?: (plan: PdpPlanWithTiers) => void,
  onAddDrugsClick?: (plan: PdpPlanWithTiers) => void,
  preferredDrugs: MedigapPreferredDrugOutput[],
  preferredPharmacies: string[]
}

export const PdpPlanList = (props: PdpPlanListProps) => {
  const [getPDPPlans, PDPPlansData] = usePDPPlans();
  const {countyName, zip} = props;
  const [pdpPlans, setPdpPlans] = React.useState<PdpPlanWithTiers[]>([]);

  React.useEffect(() => {
    if (zip && countyName) {
      getPDPPlans({variables: {
        filter: {zip, countyName, insulinSavings: props.insulinSavings, planYear: props.planYear},
        fullYear: props.planYear === GetNextPlansYear(),
        page: {page: 0, size: 1000},
        sort: [{pdpField: props.sort || PdpField.MonthlyCost, direction: PdpSort.Asc}],
        clientId: props.clientId || undefined
      }})
    }
  }, [zip, countyName, props.clientId, props.insulinSavings, props.sort, props.planYear])

  React.useEffect(() => {
    if (PDPPlansData.data?.PdpPlans) {
      setPdpPlans(PDPPlansData.data?.PdpPlans.data)
    }
  }, [PDPPlansData.data]);

  if (PDPPlansData.loading) {
    return <Box textAlign={"center"} my={2}>
      <CRPreloader />
    </Box>
  }

  return <Stack spacing={2}>
    {(!pdpPlans.length && !PDPPlansData.loading && PDPPlansData.called) && <Typography color={'textPrimary'}
                                                                          align={'center'}
                                                                          className={'fs-18 bold mt-50 mb-110'}>
      There are no plans available based on your search criteria. Try adjusting your filter settings to see more plans.
    </Typography>}
    {pdpPlans.map((plan, index) => (
      <PdpPlanCard lowestCost={!index}
                   zip={zip}
                   planYear={props.planYear}
                   countyName={countyName}
                   clientId={props.clientId}
                   key={plan.bidId}
                   preferredDrugs={props.preferredDrugs}
                   preferredPharmacies={props.preferredPharmacies}
                   onAddDrugsClick={() => props.onAddDrugsClick && props.onAddDrugsClick(plan)}
                   onPharmacyClick={() => props.onPharmacyClick && props.onPharmacyClick(plan)}
                   onSelectClick={() => props.onSelectClick && props.onSelectClick(plan)}
                   plan={plan}/>
    ))}
  </Stack>
}
